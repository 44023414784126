<template>
	<div>
		<button type="button" class="btn btn-light border w-100 text-left" data-toggle="modal" :data-target="'#'+idDF">
			<span v-if="proveedor.codigoProveedor">
				<img v-lazy="proveedor.logo" class="img-fluid" style="height: 22px;" v-if="proveedor.logo"> {{proveedor.nombre}}
			</span>
			<span v-else>
				{{titleDF}}
			</span>
		</button>

		<!-- Modal -->
		<div class="modal fade" :id="idDF" tabindex="-1" :aria-labelledby="idDF+'label'" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
				<div class="modal-content">
					<div class="modal-header align-items-center">
						<h5 class="modal-title d-flex align-items-center" :id="idDF+'label'">
							<span class="material-icons mr-1">local_shipping</span>
							{{titleDF}}
						</h5>
						
						<div>
							<button type="button" class="btn btn-link btn-sm mr-2" title="Actualizar" v-on:click="getData()">
								<span class="material-icons vertical-bottom">sync</span>
							</button>
							<button type="button" class="btn btn-link btn-sm" data-dismiss="modal" aria-label="Close">
								<span class="material-icons vertical-bottom">close</span>
							</button>
						</div>
					</div>
					<div class="modal-body" v-if="loading">
						<div class="d-flex align-items-center justify-content-center h-100">
							<h3 class="text-muted">
								<div class="spinner-grow" role="status"></div> Cargando...
							</h3>
						</div>
					</div>
					<div class="modal-body" v-else>
						<div class="mb-3 d-flex justify-content-center align-items-center" v-if="datos.length>0">
							<span class="material-icons">search</span>
							<div class="w-100">
								<input type="text" class="form-control bg-light" v-model="search" placeholder="Buscar...">
							</div>
						</div>

						<div class="row justify-content-center align-items-stretch" v-if="datosFiltered.length>0">
							<div class="col-lg-3 mb-3" v-for="(data, index) in datosFiltered" :key="index">
								<div class="custom-control custom-radio h-100">
									<input type="radio" :id="'proveedorList'+index" name="customRadio" class="custom-control-input" :value="data.codigoProveedor" v-model="valueDF" :disabled="loading || !hiddeElement" @change="selectValue($event.target.value)" :required="requiredDF">
									<label class="custom-control-label h-100 w-100" :for="'proveedorList'+index">
										<div class="d-flex flex-column align-items-stretch justify-content-center h-100">
											<div class="m-auto" v-if="data.logo">
												<img v-lazy="data.logo" class="img-fluid">
											</div>
											<div class="m-auto text-center" v-else>
												<span class="material-icons" style="font-size: 50px;">local_shipping</span>
											</div>
											<div class="mt-auto text-center text-truncate font-weight-bold">
												{{data.nombre}}
											</div>
										</div>
									</label>
								</div>
							</div>
						</div>
						<div v-else>
							<div class="py-5 text-center" v-if="datos.length>0">
								<img src="@/assets/image/no_search.svg" class="img-fluid mb-2" width="150">
								<p>No se encontraron resultados en la búsqueda: <strong>{{search}}</strong></p>
								<input type="text" :name="idDF" :required="requiredDF" class="d-none">
								<button v-on:click="getData()" class="btn btn-primary" :disabled="loading">
									<BtnLoading v-if="loading" />
									<span v-else class="d-flex align-items-center">
										<span class="material-icons">sync</span> Refrescar
									</span>
								</button>
							</div>
							<div class="py-5 text-center" v-else>
								<img src="@/assets/image/logistics.svg" class="img-fluid mb-2" width="250">
								<p>No se han encontrado proveedores</p>
								<input type="text" :name="idDF" :required="requiredDF" class="d-none">
								<button v-on:click="getData()" class="btn btn-primary" :disabled="loading">
									<BtnLoading v-if="loading" />
									<span v-else class="d-flex align-items-center">
										<span class="material-icons">sync</span> Refrescar
									</span>
								</button>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>

		<div class="position-relative" v-if="false">
			<div class="position-absolute select-icon">
	            <button type="button" class="btn btn-outline-light btn-sm border-0 text-muted shadow-0 d-flex align-items-center" title="Actualizar" v-on:click="getData()" tabindex="-1" :disabled="loading">
	            	<span class="material-icons">refresh</span>
	            </button>
	        </div>
			<select :class="[classSelect, {'custom-select border':true}]" :value="modelValue" @change="$emit('update:modelValue', $event.target.value)" style="padding-left: 42px;" :disabled="loading || !hiddeElement">
				<option value="" disabled selected>
	                <span v-if="loading">Cargando...</span>
	                <span v-else>{{titleDF}}</span>
	            </option>
				<option v-for="(data, index) in datos" :value="data.codigoProveedor" :key="index">
					<span v-if="loading">Cargando...</span>
	                <span v-else>{{data.nombre}}</span>
				</option>
			</select>
		</div>
	</div>

</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	export default {
		components: {
			BtnLoading
		},
		props: ['modelValue', 'title', 'classSelect', 'id', 'required'],
    	emits: ['update:modelValue'],
		data(){
			return {
				loading: false,
				hiddeElement: true,
				datos: [],
				valueDF: '',
				search: ''
			}
		},
		mounted(){
			if(this.datos.length<=0){
	            this.getData()
	        }else{
	            if(this.datos.length==1){
	                this.hiddeElement = false
	                this.$emit('update:modelValue', this.datos[0].codigoProveedor)
	            }else{
	                this.hiddeElement = true
	            }
	        }
		},
		methods: {
			getData(){
				this.loading = true
				this.$store.dispatch('getProveedores', {
					estado: 1
				}).then(res=>{
					this.datos = (res.data || [])
					//this.datos = []
					if(res.data.length==1){
	                    this.hiddeElement = false
	                    this.$emit('update:modelValue', res.data[0].codigoProveedor)
	                }else{
	                    this.hiddeElement = true
	                }
				}).catch(err => {
					return err
				}).then(() => {
					this.loading = false
				})
			},
			selectValue(val){
				this.$emit('update:modelValue', val)
				window.$(this.$el).children('.modal').modal('hide')
			}
		},
		computed: {
	        titleDF(){
	        	return this.title || 'Seleccionar proveedor'
	        },
	        idDF(){
	        	return this.id || 'selectProveedor'
	        },

	        requiredDF(){
	        	return this.required
	        },

	        datosFiltered(){
	        	if (this.search) {
	        		return (this.datos || []).filter(x => x.nombre.toLowerCase().includes(this.search.toLowerCase()))
	        	}
	        	return this.datos || []
	        },

	        proveedor(){
	        	return this.datos.filter(x => x.codigoProveedor == this.modelValue)[0] || {}
	        }
	    },
	    watch: {
	    	modelValue(val){
	    		this.valueDF = val
	    	}
	    },
	    beforeUnmount(){
			window.$('.modal-backdrop').remove()
		}
	}
</script>