<template>
	<div class="d-flex align-items-center" style="min-height: calc(100vh - 165px);">
		<div class="container card shadow bg-light my-3">
			<div class="card-body">
				<div class="d-flex flex-column flex-lg-row justify-content-between mb-3">
					<div>
						<h4 class="text-dark font-weight-bold mb-0">{{title}}</h4>
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb pl-0 pb-0 mb-0">
								<li class="breadcrumb-item">
									<router-link :to="{name: 'tiendas.index'}">CRECE TU TIENDA</router-link>
								</li>
								<li class="breadcrumb-item active" aria-current="page">{{title}}</li>
							</ol>
						</nav>
					</div>
				</div>

				<div class="row justify-content-center">
					<div class="col-lg-10 mb-3">
						<div class="card shadow">
							<div class="card-body">
								<form novalidate v-on:submit.prevent="update" ref="solicitud">
									<div class="row">
										<GroupInput class="col-lg-4 mb-3" icon="store">
											<label class="mb-0 font-weight-bold">Tienda</label>
											<p class="mb-0">{{form.nombreTienda}}</p>
										</GroupInput>

										<GroupInput class="col-lg-4 mb-3" icon="face">
											<label class="mb-0 font-weight-bold">Nombre de tiendero</label>
											<p class="mb-0">{{form.nombreUsuario || form.nombreTiendero}}</p>
										</GroupInput>

										<GroupInput class="col-lg-4 mb-3" icon="local_shipping">
											<label class="mb-0 font-weight-bold">Proveedor</label>
											<p class="mb-0">{{form.nombreProveedor}}</p>
										</GroupInput>

										<GroupInput class="col-lg-3 mb-3" icon="payments">
											<label class="mb-0 font-weight-bold">Monto a solicitar</label>
											<p class="mb-0">{{$filters.currency(form.monto)}}</p>
										</GroupInput>

										<GroupInput class="col-lg-3 mb-3" icon="schedule">
											<label class="mb-0 font-weight-bold">Plazo (diario)</label>
											<p class="mb-0" v-if="form.plazo==1">{{form.plazo}} mes</p>
											<p class="mb-0" v-else>{{form.plazo}} meses</p>
										</GroupInput>

										<GroupInput class="col-lg-3 mb-3" text="%">
											<label class="mb-0 font-weight-bold">Tasa</label>
											<p class="mb-0">{{form.cotizadorTasa}}%</p>
										</GroupInput>

										<GroupInput class="col-lg-3 mb-3" icon="today">
											<label class="mb-0 font-weight-bold">Cuota diaria</label>
											<p class="mb-0">{{form.cotizadorCuotaDiaria}}</p>
										</GroupInput>

										<GroupInput class="col-lg-12 mb-3" icon="description">
											<label class="mb-0 font-weight-bold">Observaciones</label>
											<p class="mb-0">{{form.observaciones}}</p>
										</GroupInput>

										<GroupInput class="col-lg-6 mb-3" icon="event" v-if="user.prf == 3">
											<label for="fechaPago" class="mb-0 font-weight-bold">Fecha de pago</label>
											<Date id="fechaPago" classInput="bg-light" v-model="form.fechaPago" required />
										</GroupInput>
										<GroupInput class="col-lg-6 mb-3" icon="event" v-else>
											<label for="fechaPago" class="mb-0 font-weight-bold">Fecha de pago</label>
											<p class="mb-0">{{$filters.date(form.fechaPago)}}</p>
										</GroupInput>

										<GroupInput class="col-lg-6 mb-3" icon="pending_actions" v-if="user.prf == 3">
											<label for="estado" class="mb-0 font-weight-bold">Estado</label>
											<select id="estado" class="custom-select bg-light" v-model="form.estado" required>
												<option value="0" disabled>Seleccionar estado</option>
												<option value="1">Aprobada</option>
												<option value="2">Rechazada</option>
											</select>
										</GroupInput>
										<GroupInput class="col-lg-6 mb-3" icon="pending_actions" v-else>
											<label class="mb-0 font-weight-bold">Estado</label>
											<div>
												<span class="badge badge-light" v-if="form.estado == 0">
													En espera
												</span>
												<span class="badge badge-primary" v-if="form.estado == 1">
													Aprobada
												</span>
												<span class="badge badge-danger" v-if="form.estado == 2">
													Rechazada
												</span>
												<span class="badge badge-info" v-if="form.estado == 3">
													Finalizado
												</span>
											</div>
										</GroupInput>
									</div>

									<div class="text-center">
										<div v-if="detalles.length>0">
											<div class="d-flex align-items-center justify-content-between">
												<div>
													<h3 class="text-muted">Productos seleccionados</h3>
												</div>
											</div>

											<div class="table-responsive" v-if="detalles.length>0">
												<table class="table">
													<thead>
														<tr>
															<th>Cantidad</th>
															<th>Producto</th>
															<th>Precio</th>
															<th>Total</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="(producto, index) in detalles" :key="index">
															<th>
																<p class="mb-0">
																	{{producto.cantidad}}
																</p>
															</th>
															<td>
																<router-link :to="{name: 'tiendas.precompra.productos.show', params: {producto: producto.slugProducto}}">
																	{{producto.tituloProducto}}
																</router-link>
															</td>
															<td class="text-right">{{$filters.currency(producto.precio)}}</td>
															<td class="text-right">
																{{$filters.currency((producto.precio || 0) *  (producto.cantidad || 0))}}
															</td>
														</tr>
														<tr>
															<td colspan="2"></td>
															<th class="text-right">
																Total:
															</th>
															<th class="text-right">
																{{$filters.currency(total || 0)}}
															</th>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>

									<div class="d-flex justify-content-between">
										<div class="mr-2">
											<button type="submit" class="btn btn-primary mr-2" :disabled="loadingSolicitud" v-if="user.prf==3">
												<BtnLoading v-if="loadingSolicitud" />
												<span v-else>
													Actualizar
												</span>
											</button>

											<router-link :to="{name: 'tiendas.index'}" class="btn btn-link" v-if="user.prf==3">Cancelar</router-link>
											<router-link :to="{name: 'tiendas.index'}" class="btn btn-link" v-else>Ir a solicitudes</router-link>
										</div>

										<a href="https://api.whatsapp.com/send/?phone=+50222341368&text=Tengo una pregunta sobre CRECE TU TIENDA&app_absent=0" target="_blank" class="btn btn-link text-primary font-weight-bold">
											Tengo una pregunta <span class="material-icons vertical-bottom">help</span>
										</a>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Date = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "formsDate" */"@/components/forms/Date.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const GroupInput = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "FormsGroupInput" */"@/components/forms/GroupInput.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		components: {
			GroupInput,
			BtnLoading,

			Date
		},
		data(){
			return {
				form: {},
				detalles: [],
				maxPlazo: 60,
				tasa: '',
				loadingSolicitud: false,
			}
		},

		mounted(){
			this.getData()
			this.getDetalle()
		},

		methods: {
			getData(){
				this.$store.dispatch('showTiendasSolicitudes', {
					codigoSolicitud: this.id
				}).then(response => {
					this.form = response.data || {}
					return response
				}).catch(error =>{
					if (error.response) {
						window.toastr.error(error.response.data.msg, 'Error')
					}else{
						window.toastr.error('Error al leer solicitud, porfavor refresca la página', 'Error')
					}
					return error
				})
			},

			getDetalle(){
				this.$store.dispatch('getDetalle', {
					codigoSolicitud: this.id
				}).then(response => {
					this.detalles = response.data || []
					return response
				}).catch(error =>{
					if (error.response) {
						window.toastr.error(error.response.data.msg, 'Error')
					}else{
						window.toastr.error('Error al leer solicitud, porfavor refresca la página', 'Error')
					}
					return error
				})
			},

			update(){
				this.loadingSolicitud = true
				this.form.actualizadoPor = this.user.idusuario
				this.$store.dispatch('updateTiendasSolicitudes', this.form).then(response => {
					window.toastr.success('La solicitud ha sido actualizada.', 'Éxito')
					this.$router.push({name: 'tiendas.index'})
					return response
				}).catch(error =>{
					if (error.response) {
						window.toastr.error(error.response.data.msg, 'Error')
					}else{
						window.toastr.error('Error al leer solicitud, porfavor refresca la página', 'Error')
					}
					return error
				}).then(() => {
					this.loadingSolicitud = false
				})
			},

		},

		computed: {
			id(){
				return this.$route.params.id || -1
			},

			loading() {
				return this.$store.getters.loading
			},

			title(){
				return this.$route.meta.title ? this.$route.meta.title : ''
			},

			user(){
				return this.$store.getters.currentUser || {}
			},

			total(){
				var sum = 0
				for (var i = 0; i < this.detalles.length; i++) {
					sum = sum + (this.detalles[i].precio || 0) * (this.detalles[i].cantidad || 0)
				}
				return sum
			},
		},
	}
</script>