<template>
	<div class="d-flex align-items-center" style="min-height: calc(100vh - 165px);">
		<div class="container card shadow bg-light my-3">
			<div class="card-body">
				<div class="d-flex flex-column flex-lg-row justify-content-between mb-3">
					<div>
						<h4 class="text-dark font-weight-bold mb-0">{{title}}</h4>
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb pl-0 pb-0 mb-0">
								<li class="breadcrumb-item">
									<router-link :to="{name: 'tiendas.index'}">CRECE TU TIENDA</router-link>
								</li>
								<li class="breadcrumb-item active" aria-current="page">{{title}}</li>
							</ol>
						</nav>
					</div>
				</div>

				<div class="row">					
					<div class="col-lg-12 mb-3">
						<div class="text-center">
							<div v-if="items.length>0">
								<div class="d-flex align-items-center justify-content-between">
									<div>
										<h3 class="text-muted">Productos seleccionados</h3>
									</div>
									<div>
										<router-link :to="{name: 'tiendas.precompra.proveedores'}" class="btn btn-sm btn-outline-primary mr-2">Buscar productos</router-link>

										<button type="button" v-on:click="removeAllItems()" class="btn btn-outline-danger btn-sm rounded-pill">
											<span class="material-icons vertical-bottom">delete</span>
											Eliminar todos
										</button>
									</div>
								</div>

								<div class="table-responsive" v-if="items.length>0">
									<table class="table">
										<thead>
											<tr>
												<th>Cantidad</th>
												<th>Producto</th>
												<th>Precio</th>
												<th>Total</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(producto, index) in items" :key="index">
												<th>
													<div class="d-flex align-items-center justify-content-between">
														<div>
															<button type="button" class="btn btn-outline-primary btn-sm" v-on:click="cantidad(-1, producto)" :disabled="producto.cantidad<=1">
																<span class="material-icons vertical-bottom">remove</span>
															</button>
														</div>
														<div class="mx-2">
															<p class="mb-0">
																{{producto.cantidad}}
															</p>
														</div>
														<div>
															<button type="button" class="btn btn-outline-primary btn-sm" v-on:click="cantidad(1, producto)">
																<span class="material-icons vertical-bottom">add</span>
															</button>
														</div>
													</div>
												</th>
												<td>
													<router-link :to="{name: 'tiendas.precompra.productos.show', params: {producto: producto.slug}}">
														{{producto.titulo}}
													</router-link>
												</td>
												<td class="text-right">{{$filters.currency(producto.precio)}}</td>
												<td class="text-right">
													{{$filters.currency((producto.precio || 0) *  (producto.cantidad || 0))}}
												</td>
												<td width="50">
													<button type="button" class="btn btn-outline-danger btn-sm rounded-pill" v-on:click="removeCart(producto.codigo)">
														<span class="material-icons">delete</span>
													</button>
												</td>
											</tr>
											<tr>
												<td colspan="2"></td>
												<th class="text-right">
													Total:
												</th>
												<th class="text-right">
													{{$filters.currency(total || 0)}}
												</th>
												<td></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							
							<div class="border border-primary p-3 rounded border-dashed text-center mb-3" v-if="isMobile">
								<p>¿Deseas adquirir una prenda?</p>
								<router-link :to="{name: 'tiendas.precompra.proveedores'}" class="btn btn-primary">Buscar productos</router-link>
							</div>
						</div>
					</div>

					<div class="col-lg-7 mb-3">
						<div class="card shadow">
							<div class="card-body">
								<form novalidate v-on:submit.prevent="enviar" ref="solicitud">
									<GroupInput class="mb-3" icon="store">
										<label for="tienda" class="mb-0 font-weight-bold">*Tienda</label>
										<Tiendas id="tienda" classSelect="bg-light" v-model="form.codigoTienda" @monto="montoHandler" required />
									</GroupInput>

									<GroupInput class="mb-3" icon="face">
										<label for="nombreTiendero" class="mb-0 font-weight-bold">*Nombre de tiendero</label>
										<input id="nombreTiendero" type="text" :class="{'form-control':true, 'bg-light':user.prf==3, 'bg-white':user.prf!=3}" v-model="form.nombreTiendero" placeholder="Nombre de tiendero" maxlength="150" required :disabled="user.prf!=3" />
									</GroupInput>

									<GroupInput class="mb-3" icon="local_shipping">
										<label for="proveedor" class="mb-0 font-weight-bold">*Proveedor</label>
										<ProveedoresModal id="proveedor" classSelect="bg-light" v-model="form.codigoProveedor" required />
									</GroupInput>

									<div class="mb-3 text-muted" v-if="montoDisponible>0">
										<label class="ml-3 mb-0 font-weight-bold">*Monto disponible: </label> {{$filters.currency(montoDisponible)}}
									</div>

									<GroupInput class="mb-3" icon="payments">
										<label for="monto" class="mb-0 font-weight-bold">*Monto a solicitar</label>
										<Currency id="monto" class="form-control bg-light" v-model="form.monto" placeholder="Ingrese monto" required />
									</GroupInput>

									<GroupInput class="mb-3" icon="schedule">
										<div class="d-flex justify-content-between align-items-center">
											<div>
												<label for="plazo" class="mb-0 font-weight-bold">*Plazo (diario)</label>
											</div>
											<div>
												<input id="plazo" type="number" v-model="form.plazo" class="form-control form-control-sm bg-light" :min="1" :max="maxPlazo" step="1" required>
											</div>
										</div>
										<div class="mt-2">
											<Slider v-model="form.plazo" :step="1" :min="1" :max="maxPlazo" :tooltips="false" />
										</div>
									</GroupInput>

									<GroupInput class="mb-3" icon="description">
										<label for="observaciones" class="mb-0 font-weight-bold">Observaciones</label>
										<textarea id="observaciones" class="form-control bg-light" v-model="form.observaciones" rows="3" placeholder="Observaciones" maxlength="250"></textarea>
									</GroupInput>

									<div class="d-flex justify-content-between">
										<div class="mr-2">
											<button type="submit" class="btn btn-primary mr-2" :disabled="loadingSolicitud || !validateForm">
												<BtnLoading v-if="loadingSolicitud" />
												<span v-else>
													Pedir producto
												</span>
											</button>

											<router-link :to="{name: 'tiendas.index'}" class="btn btn-link">Cancelar</router-link>
										</div>

										<a href="https://api.whatsapp.com/send/?phone=+50222341368&text=Tengo una pregunta sobre CRECE TU TIENDA&app_absent=0" target="_blank" class="btn btn-link text-primary font-weight-bold">
											Tengo una pregunta <span class="material-icons vertical-bottom">help</span>
										</a>
									</div>

								</form>
							</div>
						</div>	
					</div>

					<div class="col-lg-5 mb-3">
						<div class="border border-primary p-3 rounded border-dashed text-center mb-3" v-if="!isMobile">
							<p>¿Deseas adquirir productos?</p>
							<router-link :to="{name: 'tiendas.precompra.proveedores'}" class="btn btn-primary">Buscar productos</router-link>
						</div>

						<div class="card shadow">
							<div class="card-body">
								<div class="mx-auto w-50 text-center mb-3">
									<img src="@/assets/image/logo_dark.webp" class="img-fluid">
									<p class="mb-0">
										<strong>www.clubcashin.com</strong>
									</p>
								</div>

								<h2 class="text-center">Cotizador</h2>

								<div class="card bg-light">
									<div class="card-body">
										<div class="d-flex justify-content-between mb-3">
											<div>
												Tasa: 
											</div>
											<div class="font-weight-bold">
												{{form.cotizadorTasa}}%
											</div>
										</div>

										<div class="d-flex justify-content-between mb-3">
											<div>
												Total del préstamo: 
											</div>
											<div class="font-weight-bold">
												{{$filters.currency(form.cotizadorTotalPrestamo)}}
											</div>
										</div>

										<div class="d-flex justify-content-between mb-3">
											<div>
												Cuota diaria:
											</div>
											<div class="font-weight-bold">
												{{$filters.currency(form.cotizadorCuotaDiaria)}}
											</div>
										</div>

										<div class="d-flex justify-content-between mb-3">
											<div>
												Plazo en días: 
											</div>
											<div class="font-weight-bold">
												{{form.plazo}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	import Tiendas from '@/components/catalogos/Tiendas.vue'
	import ProveedoresModal from '@/components/catalogos/ProveedoresModal.vue'

	const GroupInput = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "FormsGroupInput" */"@/components/forms/GroupInput.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const Currency = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Currency" */"@/components/Currency.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Slider = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Slider" */"@vueform/slider"),
		loadingComponent: {template:`<loading-component />`}
	})

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const datosSeguros = require('@/assets/datos.json')
	
	export default {
		components: {
			GroupInput,

			Tiendas,
			ProveedoresModal,
			Currency,
			BtnLoading,

			Slider
		},
		data(){
			return {
				form: {
					codigoTienda: '',
					codigoUsuario: '',
					nombreTiendero: '',
					codigoProveedor: '',
					monto: 1,
					plazo: '',
					fechaPago: '',
					observaciones: '',
					cotizadorTasa: '',
					cotizadorTotalPrestamo: '',
					cotizadorCuotaDiaria: '',
					cotizadorPlazoDias: '',
					productos: []
				},

				maxPlazo: 7,
				tasa: '',
				montoDisponible: 0,
				loadingSolicitud: false,
				validateForm: false
			}
		},

		mounted(){
			var tasa = datosSeguros.interes['tiendas'] || '0.0175'
			this.tasa = parseFloat((tasa).replace(/[,]/g, '')) * 1.12
			this.form.plazo = 7
			if (this.user.prf!=3) {
				this.form.nombreTiendero = this.user.nombre
			}

			if (this.total>0) {
	          this.form.monto = this.total
	        }
		},

		methods: {
			enviar(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}

				this.loadingSolicitud = true
				this.form.cotizadorPlazoDias = this.plazo

				this.form.creadoPor = this.user.idusuario
				if (this.user.prf!=3) {
					this.form.nombreTiendero = this.user.nombre
				}
				
				this.form.productos = this.items || []
				this.$store.dispatch('addTiendasSolicitudes', this.form).then(response => {
					window.toastr.success('Solicitud enviada, pendiente de aprobación.', 'Éxito')
					this.form.productos = []
					this.removeAllItems()
					this.$router.push({name: 'tiendas.index'})
					return response
				}).catch(error =>{
					if (error.response) {
						window.toastr.error(error.response.data.msg, 'Error')
					}else{
						window.toastr.error('Error al registrar solicitud', 'Error')
					}
					return error
				}).then(() => {
					this.loadingSolicitud = false
				})
			},
			checkValidity(){
				if (!this.$refs.solicitud){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.solicitud.checkValidity()
				}
			},
			removeAllItems(){
				this.$store.commit('removeAllItems')
			},

			removeCart(id){
				this.$store.commit('removeItems', id)
				window.toastr.success('Producto eliminado.', 'Éxito')
			},

			cantidad(num, form){
		        this.$store.commit('addItems', {
					codigo: form.codigo,
					cantidad: form.cantidad + num,
					logo: form.logo,
					slug: form.slug,
					titulo: form.titulo,
					precio: form.precio,
				})

		        window.toastr.success('Producto actualizado.', 'Éxito')
		    },

		    montoHandler(val){
		    	this.montoDisponible = val || 0
		    }
		},

		computed: {
			loading() {
				return this.$store.getters.loading
			},

			isMobile() {
				return this.$store.getters.isMobile
			},

			items() {
				return this.$store.getters.items
			},

			title(){
				return this.$route.meta.title ? this.$route.meta.title : ''
			},

			plazo(){
				return this.form.plazo
			},

			monto(){
				return this.form.monto
			},

			cuota(){
				var d = ((this.monto || 0) * (this.tasa || 0))
				var e = (1-Math.pow((1+this.tasa), ((this.plazo || 0)*-1)))

				return d/e
			},

			user(){
				return this.$store.getters.currentUser || {}
			},

			total(){
				var sum = 0
				for (var i = 0; i < this.items.length; i++) {
					sum = sum + (this.items[i].precio || 0) * (this.items[i].cantidad || 0)
				}
				return sum
			},
		},

		watch: {
			total(val, oldValue){
				if (!this.form.monto || this.form.monto == oldValue) {
					this.form.monto = val
				}
			},
			/*plazo(val) {
				this.form.cotizadorPlazoDias = (val || 0)
			},*/
			monto(val) {
				this.form.cotizadorTotalPrestamo = val
			},
			tasa(val){
				this.form.cotizadorTasa = parseFloat(val * 100).toFixed(2)
			},
			cuota(val){
				this.form.cotizadorCuotaDiaria = parseFloat((val || 0)).toFixed(2)
			},

			'user':{
				handler(val){
					if (val.prf!=3) {
						this.form.nombreTiendero = val.nombre
					}
				},
				deep: true
			},

			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		}
	}
</script>