<template>
	<div class="d-flex" style="min-height: calc(100vh - 165px);">
		<div class="container card bg-light my-3 shadow">
			<div class="card-body">
				<div v-if="loadingSolicitudes" class="d-flex align-items-center justify-content-center h-100">
					<h3 class="text-muted">
						<div class="spinner-grow" role="status"></div> Cargando...
					</h3>
				</div>
				<div class="row justify-content-center" v-else>
					<div class="col-lg-12">
						<div class="d-flex flex-column flex-lg-row justify-content-between mb-3">
							<div>
								<h4 class="text-dark font-weight-bold mb-0">{{title}}</h4>
							</div>
							<div>
								<div class="d-flex align-items-center h-100 justify-content-end justify-content-lg-start">
									<button type="button" class="btn btn-white mr-2" v-on:click="getData()" title="Actualizar">
										<span class="material-icons vertical-bottom">sync</span>
									</button>
									<div class="mr-2" v-if="solicitudes.length>0">
										<select class="custom-select" v-model="filters.estado">
											<option value="">
												<span v-if="filters.estado">
													Ver todas
												</span>
												<span v-else>
													Estado
												</span>
											</option>
											<option value="0">En espera</option>
											<option value="1">Aprobadas</option>
											<option value="2">Rechazadas</option>
											<option value="3">Finalizadas</option>
										</select>
									</div>

									<div>
										<router-link class="btn btn-outline-primary w-100 w-lg-auto" :to="{name: 'tiendas.create'}">
											<span class="material-icons vertical-bottom" title="Crear registro">add</span>
											Agregar
										</router-link>
									</div>
								</div>
							</div>
						</div>
						<div class="row justify-content-center align-items-center">
							<div class="col-md-12">
								<div class="card shadow">
									<div class="card-body">
										<div class="table-responsive" v-if="solicitudesFiltered.length>0">
											<table class="table table-hover">
												<thead>
													<tr>
														<th width="40px">Código</th>
														<th>Tienda</th>
														<th>Nombre</th>
														<th>Producto</th>
														<th>Monto</th>
														<th>Estado</th>
														<th width="110px"></th>
													</tr>
													<tr>
														<td class="p-1" colspan="5">
															<div class="text-muted text-uppercase font-weight-bolder text-monospace text-left">
																Solicitudes ({{solicitudes.length}})
															</div>
														</td>
													</tr>
												</thead>
												<tbody>
													<tr class="cursor-pointer" v-for="(solicitud, index) in solicitudesFiltered" :key="index" v-on:click="editShow(solicitud.codigoSolicitud, 'show')">
														<th>{{solicitud.codigoSolicitud}}</th>
														<td>
															{{solicitud.nombreTienda}}
														</td>
														<td>
															{{solicitud.nombreUsuario || solicitud.nombreTiendero}}
														</td>
														<td>
															{{solicitud.nombreProveedor}}
														</td>
														<td align="right">
															{{$filters.currency(solicitud.monto)}}
														</td>
														<td align="center">
															<span class="badge badge-light" v-if="solicitud.estado == 0">
																En espera
															</span>
															<span class="badge badge-primary" v-if="solicitud.estado == 1">
																Aprobada
															</span>
															<span class="badge badge-danger" v-if="solicitud.estado == 2">
																Rechazada
															</span>
															<span class="badge badge-info" v-if="solicitud.estado == 3">
																Finalizado
															</span>
														</td>
														<td width="150px" align="right">
															<button type="button" class="btn btn-link btn-sm" v-on:click.stop="deleteSolicitud(solicitud.codigoSolicitud)" v-if="solicitud.estado < 1 || user.prf==3">
																<span class="material-icons vertical-bottom" title="Eliminar">delete</span>
															</button>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div v-else>
											<div class="py-5 text-center" v-if="solicitudes.length>0">
												<img src="@/assets/image/no_search.svg" class="img-fluid mb-2" width="150">
												<p>No se encontraron resultados en la búsqueda.</p>
												<router-link class="btn btn-primary" :to="{name: 'tiendas.create'}">
													<span class="material-icons vertical-bottom" title="Crear registro">add</span>
													Agregar solicitud
												</router-link>
											</div>
											<div class="py-5 text-center" v-else>
												<img src="@/assets/image/add_notes.svg" class="img-fluid mb-2" width="250">
												<p>No se han encontrado solicitudes</p>
												<router-link class="btn btn-primary" :to="{name: 'tiendas.create'}">
													<span class="material-icons vertical-bottom" title="Crear registro">add</span>
													Agregar solicitud
												</router-link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<router-view/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	export default {
		data(){
			return {
				filters: {
					estado: '',
				},
				loadingSolicitudes: false
			}
		},
		mounted(){
			this.getData()
		},
		methods: {
			getData(){
				this.loadingSolicitudes = true
				this.$store.dispatch('getSolicitudes', {
					codigoUsuario: this.user.idusuario || -1
				}).then(() =>{
					this.loadingSolicitudes = false
				}).catch()
			},

			editShow(id, module='edit'){
				var route = 'tiendas.'+module
				this.$router.push({name: route, params: {id: id}})
			},

			deleteSolicitud(id){
				var vm = this
				$.confirm({
					title: '¿Estás seguro?',
					content: '¡Ya no podrás recuperar esta información!',
					theme: 'supervan', // 'material', 'bootstrap'
					buttons: {
						Confirmar: function () {
							vm.$store.dispatch('deleteTiendasSolicitudes', {
								codigoSolicitud: id
							})
							.then(response => {
								vm.getData()
								window.toastr.success('Registro eliminado.', 'Éxito')
								return response
							}).catch(error =>{
								window.toastr.error('Error al eliminar registro.', 'Error')
								return error
							})
						},
						Cancelar: function () {
						},
					}
				})
			}
		},

		computed: {
			solicitudes() {
				return this.$store.getters.tiendasSolicitudes || []
			},

			solicitudesFiltered() {
				if (this.filters.estado) {
					return this.solicitudes.filter(x => x.estado == this.estado)
				}

				return this.solicitudes
			},

			title(){
				return this.$route.meta.title ? this.$route.meta.title : ''
			},

			estado(){
				return this.filters.estado || ''
			},

			user(){
				return this.$store.getters.currentUser || {}
			}
		},
	}
</script>