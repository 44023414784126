<template>
	<div class="d-flex bg-white" style="min-height: calc(100vh - 165px);">
		<div class="container my-3">
			<div class="d-flex justify-content-between mb-3">
				<div>
					<h4 class="text-dark font-weight-bold mb-0">{{$filters.slugToString(proveedor)}}</h4>
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb bg-transparent pl-0 pb-0 mb-0">
							<li class="breadcrumb-item">
								<router-link :to="{name: 'tiendas.index'}">CRECE TU TIENDA</router-link>
							</li>
							<li class="breadcrumb-item">
								<router-link :to="{name: 'tiendas.precompra.proveedores'}">PRE COMPRA</router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">{{title}}</li>
						</ol>
					</nav>
				</div>
				<div>
					<button type="button" class="btn btn-link btn-sm mr-2" title="Actualizar" v-on:click="getData()">
						<span class="material-icons vertical-bottom">sync</span>
					</button>
				</div>
			</div>

			<div v-if="loading">
				<div class="d-flex align-items-center justify-content-center h-100">
					<h3 class="text-muted">
						<div class="spinner-grow" role="status"></div> Cargando...
					</h3>
				</div>
			</div>
			<div v-else>
				<div class="mb-3 d-flex justify-content-center align-items-center" v-if="datos.length>0">
					<span class="material-icons">search</span>
					<div class="w-100">
						<input type="text" class="form-control bg-light" v-model="search" placeholder="Buscar...">
					</div>
				</div>
				<div class="row justify-content-center align-items-stretch" v-if="datosFiltered.length>0">
					<div :class="{'col-lg-3 mb-3 border-bottom border-left border-top':true, 'border-right':((index+1)%4==0) || isMobile || (index+1) == datosFiltered.length}" v-for="(data, index) in datosFiltered" :key="index">
						<router-link :to="{name: 'tiendas.precompra.productos', params: {proveedor: data.slugProveedor, categoria: data.slug}}" class="h-100 w-100">
							<div class="d-flex flex-column align-items-stretch justify-content-center h-100">
								<div class="m-auto" v-if="data.logo">
									<img v-lazy="data.logo" class="img-fluid">
								</div>
								<div class="m-auto text-center" v-else>
									<span class="material-icons" style="font-size: 50px;">local_shipping</span>
								</div>
								<div class="mt-auto text-center text-truncate font-weight-bold">
									{{data.descripcion}}

									<p class="text-muted">
										{{data.productos}} productos
									</p>
								</div>
							</div>
						</router-link>
					</div>
				</div>
				<div v-else>
					<div class="py-5 text-center" v-if="datos.length>0">
						<img src="@/assets/image/no_search.svg" class="img-fluid mb-2" width="150">
						<p>No se encontraron resultados en la búsqueda: <strong>{{search}}</strong></p>
						<button v-on:click="getData()" class="btn btn-primary" :disabled="loading">
							<BtnLoading v-if="loading" />
							<span v-else class="d-flex align-items-center">
								<span class="material-icons">sync</span> Refrescar
							</span>
						</button>
					</div>
					<div class="py-5 text-center" v-else>
						<img src="@/assets/image/logistics.svg" class="img-fluid mb-2" width="250">
						<p>No se han encontrado categorías</p>
						<button v-on:click="getData()" class="btn btn-primary" :disabled="loading">
							<BtnLoading v-if="loading" />
							<span v-else class="d-flex align-items-center">
								<span class="material-icons">sync</span> Refrescar
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	export default {
		components: {
			BtnLoading
		},
		data(){
			return {
				loading: false,
				datos: [],
				search: ''
			}
		},
		mounted(){
			this.getData()
		},
		methods: {
			getData(){
				this.loading = true
				this.$store.dispatch('getProveedoresCategorias', {
					estado: 1,
					slugProveedor: this.proveedor
				}).then(res=>{
					this.datos = (res.data || [])
				}).catch(err => {
					return err
				}).then(() => {
					this.loading = false
				})
			},
		},
		computed: {
			proveedor(){
				return this.$route.params.proveedor || -1
			},

			datosFiltered(){
	        	if (this.search) {
	        		return (this.datos || []).filter(x => x.descripcion.toLowerCase().includes(this.search.toLowerCase()))
	        	}
	        	return this.datos || []
	        },

	        title(){
				return this.$route.meta.title ? this.$route.meta.title : ''
			},

			isMobile(){
				return this.$store.getters.isMobile
			},
		}
	}
</script>