<template>
	<div class="position-relative">
		<div v-if="datos.length>0">
			<div class="position-absolute select-icon" style="z-index: 1;">
	            <button type="button" class="btn btn-outline-light btn-sm border-0 text-muted shadow-0 d-flex align-items-center" title="Actualizar" v-on:click="getData()" tabindex="-1" :disabled="loading">
	            	<span class="material-icons">refresh</span>
	            </button>
	        </div>
			<select :class="[classSelect, {'custom-select border':true}]" :value="modelValue" @change="onChange($event.target.value)" style="padding-left: 42px;" :disabled="loading || !hiddeElement" :required="requiedDF" :id="idDF">
				<option value="" disabled selected>
	                <span v-if="loading">Cargando...</span>
	                <span v-else>{{titleDF}}</span>
	            </option>
				<option v-for="(data, index) in datos" :value="data.codigoTienda" :key="index">
					<span v-if="loading">Cargando...</span>
	                <span v-else>{{data.nombre}} ({{data.sucursal}})</span>
				</option>
			</select>
		</div>
		<div v-else>
			<input type="text" :name="idDF" :required="requiedDF" class="d-none">
			<div class="alert alert-primary alert-dismissible fade show" role="alert">
				<div class="d-flex align-items-center">
					<div>
						<a href="#" v-on:click.prevent="getData()" :disabled="loading">
							<span class="material-icons vertical-bottom">sync</span>
						</a>
						No tienes asignada ninguna tienda 
						<strong>
							<a href="https://api.whatsapp.com/send/?phone=+50222341368&text=Tengo una pregunta sobre CRECE TU TIENDA&app_absent=0" target="_blank" class="text-primary font-weight-bold">
								contáctanos
							</a>
						</strong>
					</div>
					<div>
						<button type="button" class="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	export default {
		props: ['modelValue', 'title', 'classSelect', 'required', 'id'],
    	emits: ['update:modelValue', 'monto'],
		data(){
			return {
				loading: false,
				hiddeElement: true,
				datos: []
			}
		},
		mounted(){
			if(this.datos.length<=0){
	            this.getData()
	        }else{
	            if(this.datos.length==1){
	                this.hiddeElement = false
	                this.$emit('update:modelValue', this.datos[0].codigoTienda)
	            }else{
	                this.hiddeElement = true
	            }
	        }
		},
		methods: {
			getData(){
				this.loading = true
				this.$store.dispatch('getTiendas', {
					estado: 1,
					codigoUsuario: this.user.idusuario
				}).then(res=>{
					this.datos = (res.data || [])
					if(res.data.length==1){
	                    this.hiddeElement = false
	                    this.$emit('update:modelValue', res.data[0].codigoTienda)
	                }else{
	                    this.hiddeElement = true
	                }
				}).catch(err => {
					return err
				}).then(() => {
					this.loading = false
				})
			},
			onChange(val){
				this.$emit('update:modelValue', val)

				var dd = this.datos.filter(x=>x.codigoTienda == val)
				dd = dd[0] || {}
				if (dd.montoDisponible){
					this.$emit('monto', dd.montoDisponible)
				}

			}
		},
		computed: {
	        titleDF(){
	        	return this.title || 'Seleccionar tienda'
	        },

	        user(){
				return this.$store.getters.currentUser || {}
			},
			requiedDF(){
				return this.required
			},

			idDF(){
				return this.id || 'tiendaSelect'
			}
	    }
	}
</script>