<template>
	<div class="d-flex" style="min-height: calc(100vh - 165px);">
		<div class="container my-3">
			<div class="row">
				<div class="col-lg-6 mb-3">
					<div class="m-auto" v-if="form.logo">
						<img v-lazy="form.logo" class="img-fluid">
					</div>
					<div class="m-auto text-center" v-else>
						<span class="material-icons" style="font-size: 250px;">local_shipping</span>
					</div>
				</div>
				<div class="col-lg-6 mb-3">
					<div class="card shadow">
						<div class="card-body">
							<h3>{{form.titulo}}</h3>

							<h4>{{$filters.currency(form.precio)}}</h4>

							<p>{{form.descripcion}}</p>

							<div class="d-flex mb-3">
								<div class="mr-2 w-100">
									<div class="d-flex">
										<div>
											<button type="button" class="btn btn-primary" v-on:click="removeCantidad()">
												<span class="material-icons vertical-bottom">remove</span>
											</button>
										</div>
										<div class="mx-2">
											<input type="number" v-model="cantidad" class="form-control bg-light">
										</div>
										<div>
											<button type="button" class="btn btn-primary" v-on:click="addCantidad()">
												<span class="material-icons vertical-bottom">add</span>
											</button>
										</div>
									</div>
								</div>
								<div class="w-100">
									<button type="button" class="btn btn-primary w-100" v-on:click="addCart()">
										<span class="material-icons vertical-bottom">shopping_cart</span> Añadir al carrito
									</button>
								</div>
							</div>

							<hr>

							<ul class="list-unstyled mb-3">
								<li class="mb-2" v-if="form.nombreProveedor">
									<strong>Proveedor: </strong>
									<router-link :to="{name: 'tiendas.precompra.categorias', params: {proveedor: form.slugProveedor}}" class="text-info">
										{{form.nombreProveedor}}
									</router-link>
								</li>

								<li v-if="form.descripcionCategoria">
									<strong>Categoría: </strong>
									<router-link :to="{name: 'tiendas.precompra.productos', params: {proveedor: form.slugProveedor, categoria: form.slugCategorias}}" class="text-info">
										{{form.descripcionCategoria}}
									</router-link>
								</li>
							</ul>

							<div class="row align-items-center mb-3">
								<div class="col-lg-6 text-center">
									<router-link :to="{name: 'tiendas.precompra.proveedores'}" class="btn btn-outline-primary">
										Ir a PRE COMPRA
									</router-link>
								</div>
								<div class="col-lg-6 text-center">
									<router-link :to="{name: 'tiendas.precompra.proveedores'}" class="btn btn-outline-secondary">
										Ir a CRECE TU TIENDA
									</router-link>
								</div>
							</div>

							<div class="d-print-none text-center">
								<div>
									Compartir:
								</div>

								<ShareSocial
									network="facebook"
									class="btn btn-primary btn-sm mr-2"
									:url="sharing.url"
									:title="sharing.title"
									:description="sharing.description"
									:quote="sharing.quote"
									:hashtags="sharing.hashtags"
									:twitterUser="sharing.twitterUser">
									<div class="d-flex align-items-center">
										<img src="@/assets/image/facebook_white.webp" class="img-fluid mr-1" style="width: 18px;" alt="Facebook"> Facebook
									</div>
								</ShareSocial>
								<ShareSocial
									network="whatsapp"
									class="btn btn-success btn-sm mr-2"
									:url="sharing.url"
									:title="sharing.title"
									:description="sharing.description"
									:quote="sharing.quote"
									:hashtags="sharing.hashtags"
									:twitterUser="sharing.twitterUser">
									<div class="d-flex align-items-center">
										<img src="@/assets/image/whatsapp_white.webp" class="img-fluid mr-1" style="width: 18px;" alt="WhatsApp"> Whatsapp
									</div>
								</ShareSocial>

								<div class="btn-group" role="group">
									<button id="share" type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										Compartir
									</button>
									<ul class="dropdown-menu" aria-labelledby="share">
										<li class="dropdown-item" v-for="network in networks" :key="network.network">
											<ShareSocial
												:network="network.network"
												:key="network.network"
												:class="network.class"
												:url="sharing.url"
												:title="sharing.title"
												:description="sharing.description"
												:quote="sharing.quote"
												:hashtags="sharing.hashtags"
												:twitterUser="sharing.twitterUser">
												{{network.nombre}}
											</ShareSocial>
										</li>
										<li class="dropdown-item">
											<a href="#" v-on:click.prevent="print()">
												Imprimir
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	
	const ShareSocial = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "ShareSocial" */"@/components/ShareSocial.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	export default {
		components: {
			ShareSocial
		},
		data(){
			return {
				cantidad: 1,
				loading: false,
				form: [],
			}
		},
		mounted(){
			this.getData()
		},
		methods: {
			getData(){
				this.loading = true
				this.$store.dispatch('showProducto', {
					slug: this.producto,
				}).then(res=>{
					this.form = (res.data || {})

					var dd = this.items.filter(x => x.codigo == this.form.codigo)
					if (dd.length>0) {
						this.cantidad = dd[0].cantidad ?? 1
					}else{
						this.cantidad = 1
					}
				}).catch(err => {
					return err
				}).then(() => {
					this.loading = false
				})
			},

			print(){
				window.print();
			},
			addCart(){
				this.$store.commit('addItems', {
					codigo: this.form.codigo,
					cantidad: this.cantidad,
					logo: this.form.logo,
					slug: this.form.slug,
					titulo: this.form.titulo,
					precio: this.form.precio,
				})
				window.toastr.success('Producto agregado')
				this.$router.push({name: 'tiendas.create'})
			},
			addCantidad(){
				this.cantidad++
			},
			removeCantidad(){
				this.cantidad--
			}
		},
		computed: {
			producto(){
				return this.$route.params.producto || -1
			},

			title(){
				return this.$route.meta.title ? this.$route.meta.title : ''
			},

			items() {
				return this.$store.getters.items
			},

			sharing(){
				return {
					url: window.location.href || '',
					title: this.form.titulo || '',
					description: this.form.descripcion || '',
					quote: '',
					hashtags: 'clubcashin,crecetutienda',
					twitterUser: ''
				}
			},
			networks(){
				return [
					{network:'twitter', nombre: 'Twitter', class:'', style: '', icon: [ 'fab',  'twitter']},
					{network:'sms', nombre: 'SMS', class:'', icon: 'sms'},
					{network:'email', nombre: 'Email', class:'', icon: 'envelope-open-text'}
				]
			},
		}
	}
</script>